<template>
	<div>
		<audio v-if="created" ref="player" :src="src"></audio>
	</div>
</template>

<script>
export default {
	name : 'message-ring',
	props : {
		src : ''
	},
	data(){
		return {
			created : false
		}
	},
	created(){
		//console.log('提示音创建')
	},
	mounted(){
		//console.log('提示音载入')
		//console.log(this.src)
	},
	methods : {
		create(){
			this.created = true
		},
		play(){
			if(this.created){
				try{
					//this.$refs.player.play()
				}catch{}
			}
		}
	}
}
</script>