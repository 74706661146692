<template>
<div class="fx w100 h100 dialog-box">
	
	<div class="f1 fx fc w100 h100 dialog-main">
	
		<div class="fx yc win-top">
			<img :src="user.face" class="logo">
			<span>{{user.nickname}}</span>
			<span class="f1"></span>
			<div class="tool">
				<van-icon name="exchange" size="24" color="#666" title="转接对话" @click="toExchange" v-if="Level=='admin'"/>
			</div>
		</div>
		<div class="f1 win-center" :id="dialogId">
			<div class="msg-list">
				<div class="more">
					<span @click="loadHistoryMessage" v-if="!historyLoading">查看历史消息</span>
					<van-loading  color="#32ae57" size="12" v-if="historyLoading">加载中...</van-loading>
				</div>
				<div class="item" v-for="item in list" :key="item.id">
					
					<!-- 收到的消息 -->
					<div class="fx xl receive" v-if="item.isLeft == 1">
						<div class="fx inner">
							<div class="fx yc xc face"><img :src="item.face"></div>
							<div class="f1 fx fc right">
								<div class="fx xl name">{{item.nickname}} {{item.time}}</div>
								<!-- <div class="content" v-if="item.type=='normal'">{{item.content.text}}</div> -->
								<div class="content" v-if="item.type=='normal'" v-html="item.content.text"></div>
								<div class="content" v-if="item.type=='image'">
									<div class="image"><img @click="msgImageClick(item)" :src='item.content.url'></div>
								</div>
								<div class="content" v-if="item.type=='file'">
									<div class="file">
										<div class="fx yc info">
											<div class="icon"><van-icon name="link-o" size="24" /></div>
											<div class="name">{{item.content.name}}</div>
										</div>
										<!-- <div class="download"><a :href="item.content.url" target="_blank">下载附件</a></div> -->
										<div class="download"><a @click="msgFileClick(item)">下载附件</a></div>
									</div>
								</div>
							</div>
						</div>
					</div>
					
					<!-- 发出的消息 -->
					<div class="fx xr send" v-if="item.isLeft == 0">
						<div class="fx inner">
							<div class="f1 fx fc yb left">
								<div class="fx xr name">{{item.time}}</div>
								<!-- <div class="content" v-if="item.type=='normal'">{{item.content.text}}</div> -->
								<div class="content" v-if="item.type=='normal'" v-html="item.content.text"></div>
								<div class="content" v-if="item.type=='image'">
									<div class="image"><img @click="msgImageClick(item)" :src='item.content.url'></div>
								</div>
								<div class="content" v-if="item.type=='file'">
									<div class="fx fc yb file">
										<div class="fx yc info">
											<div class="icon"><van-icon name="link-o" size="24" /></div>
											<div class="name">{{item.content.name}}</div>
										</div>
										<!-- <div class="download"><a :href="item.content.url" target="_blank">下载附件</a></div> -->
										<div class="download"><a @click="msgFileClick(item)">下载附件</a></div>
									</div>
								</div>
							</div>
							<div class="fx yc xc face">
								<img src="../../static/img/me.png">
							</div>
							<div class="tips">
								<message-sending v-if="item.send_status==0"></message-sending>
								<span v-if="item.send_status==2" style='color:#f10101' title='点击重发'>发送失败</span>
							</div>
						</div>
					</div>
				</div>
		
			
			</div>
		</div>
		<div class="fx fc win-bottom">
			<message-editor Level="agent" 
			@onNormalMsg="beforeNormalMessage"
			@onImageMsg="beforeImageMessage"
			@onFileMsg="beforeFileMessage"
			></message-editor>
		</div>
	</div>
	
	<div class="flex dialog-right" v-if="Level=='admin'">
		<div style="padding:0px">
			<div class="fx w100 right-tabs">
				<span class="f1 fx yc xc active">信息</span>
				<span class="f1 fx yc xc">订单</span>
			</div>
			<span>需要显示些什么呢</span>
		</div>
		<div class="f1 right-content">
			
		</div>
	</div>
	
	
	
</div>
</template>

<script>
import JS from "./js.js"
export default{
	...JS
}
</script>

<style>
@import url("style.css");
</style>