import Vue from 'vue'
import Router from 'vue-router'
//import HelloWorld from '@/components/HelloWorld'
import PageIndex from '@/pages/index/index'
import PageAdminChat from '@/pages/admin/chat'

Vue.use(Router)

//export default new Router({
const router = new Router({
	mode: "history",
	base: process.env.BASE_URL, // 基本URL，默认为'/'
	routes: [
		{
			path: '/',
			meta : {
				title : '首页'
			},
			component: PageIndex
		},

		{
			path : '/chat',
			meta : {
				title : '对接工作台'
			},
			component : PageAdminChat
		}
		/*,
		{
			path: '/user',
			//name: 'shenme',
			//component: User
			//name : 'user',
			meta : {
				title : 'sm'
			},
			component: resolve => require(['@/components/User'], resolve), //懒加载方式
		},
		*/


	]
})

//设置标题
router.beforeEach((to, from, next) => {
	document.title = to.meta.title || '' // 从路由元信息获取标题并赋值给document.title
	next()
})

export default router

