<template>
	<div class="fx fc w100 h100 message-form" :id="editorId">
		<div class="fx yc tool">
			<van-icon name="photo-o" size="20" title='图片' @click="toSelectImage"/>
			<van-icon name="link-o" size="20" title='附件' @click="toSelectFile"/>
		</div>
		<div class="f1 pre" contenteditable="true" spellcheck="false" tabindex="0"
			@keydown.enter.prevent="huiche" 
			@dragenter.prevent="handleDragEnter"
			@dragover.prevent="handleDragOver"
			@drop.prevent="handleDrop"
			@paste="handlePaste"
		></div>
		<div class="fx yc xr btn">
			<span class="fx yc xc send" @click="msgSendNormal">发送 (Enter)</span>
		</div>
		
		<lay-loading ref="loading" size="40"></lay-loading>
	</div>
</template>

<!--消息输入框组件-->

<script>
import Vue from 'vue';
import $ from 'jquery';
import tool from '@/static/js/tool.js'
import LayLoading from '@/components/lay-loading/lay-loading.vue'
import { Icon,Toast } from 'vant';

Vue.use(Icon);
Vue.use(Toast);

export default {
	name : 'message-editor',
	components : {
		LayLoading
	},
	data(){
		return {
			editorId : 'editor',
		}
	},
	props : {
		Level : {
			type : String,
			required : true
		}
	},
	created(){
		this.editorId = 'editor'+Math.floor(Math.random() * 9000 + 1000);
	},
	mounted(){
		
	},
	methods : {
		//去选择图片
		async toSelectImage(){
			let res = await tool.selectLocalFile()
			if(!res){
				return
			}
			this.$refs.loading.show('上传中...')
			res = await tool.uploadLocalFile(res,this.Level)
			this.$refs.loading.hide()
			if(res.code == 0){
				this.$emit('onImageMsg',res.data.url)
			}
		},
		//去选择附件
		async toSelectFile(){
			let file = await tool.selectLocalFile('.pdf,.doc,.docx,.xls,.xlsx,.zip,.rar,.txt')
			if(!file){
				return
			}
			console.log('开始上传文件',file)
			this.$refs.loading.show('上传中...')
			let res = await tool.uploadLocalFile(file,this.Level)
			this.$refs.loading.hide()
			console.log(res)
			if(res.code == 0){
				this.$emit('onFileMsg',file.name,res.data.url)
			}else{
				Toast(res.info)
			}
		},
		huiche(event){
			event.preventDefault();
			this.msgSendNormal();
		},
		handleDragEnter(event){
			console.log(event);
		},
		handleDragOver(event) {
			// 可以添加一些视觉反馈
			//console.log('enter')
		},
		handleDrop(event) {
		  const files = event.dataTransfer.files;
		  console.log(files)
		  //this.handleFiles(files);
		},
		handleFiles(files) {
		  [...files].forEach(file => {
			// 检查文件类型
			if (file.type.startsWith('image/')) {
			  const reader = new FileReader();
			  reader.onload = e => {
				// 这里可以做进一步处理，比如显示图片等
				console.log(e.target.result); // 图片的Base64编码
			  };
			  reader.readAsDataURL(file);
			} else {
			  console.log('文件不是图片类型');
			}
		  });
		},
		//粘贴
		async handlePaste(event){
			const clipboardData = event.clipboardData || window.clipboardData;
			if(!clipboardData || clipboardData.items.length < 1){
				return
			}
			//application/x-zip-compressed
			//text/plain  
			//只处理一个数据
			var item = clipboardData.items[0];
			console.log('kind',item.kind,'type',item.type)
			if(item.kind == 'file'){
				
				if(item.type.indexOf('image') != -1){
					//粘贴的图片
					event.preventDefault();
					const file = item.getAsFile()
					const blob = new Blob([file],{type:file.type})
					console.log('image',file,blob)
					this.$refs.loading.show('上传中...')
					let res = await tool.uploadLocalFile(file,this.Level)
					this.$refs.loading.hide()
					if(res.code == 0){
						this.$emit('onImageMsg',res.data.url)
					}
				}else{
					event.preventDefault();
					if(item.type == 'text/plain'){
						//粘贴的txt文件，文件，文件，
						console.log('txt文档')
						
					}else if(item.type.indexOf('zip') != -1){
						//zip文件
						
					}else{
						console.log('不支持的文件')
					}
				}
			}else if(item.kind == 'string'){
				if(item.type != 'text/plain'){
					console.log('不支持的文本')
					event.preventDefault();
				}
			}
			
		},
		//发送常规消息
		msgSendNormal(){
			let edt = $('#' + this.editorId).find('.pre');
			let msg = $(edt).text();
			//console.log(msg.length)
			msg = msg.replace(/ /g,'');
			if(msg == ''){
				return
			}
			var lines = msg.split(/[\r\n]+/);
			var trimmedLines = lines.filter(function(line) {  
				return line.trim() !== ''; // 去除空白行  
			});

			$(edt).text('')
			this.$emit('onNormalMsg',trimmedLines.join('<br>'))
		}
	}
}
</script>
<style scroped>
.message-form{}
.message-form .tool{ padding:10px 10px 5px; user-select:none; }
.message-form .tool .item{ width:18px; height:18px; }
.message-form .tool .item.img{ background:url(../../static/img/tool1.png) no-repeat; background-position:-92px -7px; }
/*.message-form .tool .item.img:hover{ background-position:-64px -7px; }*/
.message-form .tool i{ margin-right:15px; }
.message-form .pre{ margin:0; text-align:left; outline:0; margin:5px; color:#000; font-size:14px; overflow-y:auto; 
/*加了white-space: pre-wrap;之后，jqueury.text()才能获取到\n换行符*/
white-space: pre-wrap;
}
.message-form .btn{ padding:0 10px 10px; }
.message-form .btn span{ font-size:12px; height:26px; border:1px solid #d9d9d9; border-radius:5px; padding:0 10px; line-height:1em; user-select:none; color:#333; }
.message-form .btn span:hover{ color:#000; }
</style>