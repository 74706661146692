// 创建一个 Event Bus  
import Vue from 'vue';
import cfg from "@/static/js/config.js"

//全局消息
const EventBus = new Vue(); 

var ws = null;
var wsReconnectTimer = null;
//重新连接消息服务器
function connect(){
	clearInterval(wsReconnectTimer)
	ws = null;
	ws = new WebSocket(cfg.wsHost);
	ws.onopen = function(){
		if(cfg.isDev){
			console.log('连接成功')
		}
	}
	ws.onmessage = function(e){
		handleWsMsg(e);
	},
	ws.onerror = function(){
		//比如连接失败
		if(cfg.isDev){
			console.log('ws error')
		}
	}
	ws.onclose = function(){
		if(cfg.isDev){
			console.log('连接断开,2秒后重连')
		}
		clearTimeout(wsReconnectTimer)
		wsReconnectTimer = setTimeout(()=>{
			connect()
		},2000)
	}
}

//消息接收
async function handleWsMsg(e){
	var obj = null;
	try{
		obj = JSON.parse(e.data);
	}catch{
		obj = {type:''}
	}
	var type = obj.type || '';
	var data = obj.data || {};
	var chatId = obj.chatId || '';
	//this.$root.$emit('onWsMsgReceived',type,data,chatId);
	EventBus.$emit('onWsMsgReceived',type,data,chatId);
}

export default{
	ws_connect : connect,
	ws_eventbus : EventBus
}