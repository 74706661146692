<template>
	<div class="fx fc app">
		
		<message-dialog-box :ChatId="chatId" Level="agent" v-if="chatId>0"></message-dialog-box>
		<message-ring ref='msgPlayer' :src='msgVoice'></message-ring>
	
		<div class="fx xc yc ws-init" v-if="wsInitMessage!=''">{{wsInitMessage}}</div>
	</div>
</template>
<script>
import indexjs from "./indexjs.js"
export default {
	...indexjs
}
</script>
<style scroped>
.ws-init{ position: fixed; z-index:88888; left:0; bottom:0; width:100%; height: 100%; background-color: rgba(0,0,0,.2); font-size:36px; color:#fff; user-select: none;}
</style>