import $ from "jquery"
import tool from "@/static/js/tool.js"
import MessageRing from '@/components/message-ring/message-ring.vue'
import MessageDialogBox from '@/components/message-dialog-box/message-dialog-box.vue'
import yourAudio from '@/static/voice/y1981.wav'
import {Notify,Icon} from 'vant'
import 'vant/lib/index.css'


export default {
	components: {
		MessageDialogBox,
		MessageRing
    },
	
	data(){
		return {
			chatId : 0,
			wsInitMessage : '正在连接服务器...', //socket初始化消息，空表示初始化成功
			
			name : '张三',
			newMsg : '',
			ws : null,
			wsReconnectTimer : null,
			msgVoice : yourAudio,
			
		}
	},
	mounted(){
		//console.log(this.$route.params)
		//console.log(this.$route.query)
		let token = this.$route.query['token'] || ''
		if(this.$cfg.isDev){
			token = 'OtcJSBdKQHENxtmRcfHDCctHwJjaCAetCAysqVPrWKlwGbrdrkmGLboQjzhH'
		}

		if(token != ''){
			tool.setAgentToken(token)
		}
		this.$refs.msgPlayer.create()
		
		const that = this
		this.$wsEventBus.$on('onWsMsgReceived',function(type,data,chatId){
			that.handleWsMsg(type,data,chatId);
		})
		this.$wsInit();
		
	},
	methods : {

		//消息接收
		async handleWsMsg(type,data,chatId){
			switch(type){
				case 'init':
					let res = await tool.postAgent('init',{
						client_id : data
					})
					if(res.code == 0){
						this.chatId = res.data.chatId;
						this.wsInitMessage = '';
					}else if(res.code == 1){
						//Notify({ type: 'primary', message: res.info,duration:10000 });
					}else if(res.code == -1){
						this.wsInitMessage = res.info;
					}
					break;
				case 'normal' :
				case 'image' :
				case 'file' :
					console.log(data)
					this.$wsEventBus.$emit('onChatMessageReceived',chatId,data);
					this.$refs.msgPlayer.play()
					break;
				case 'exchange':
					console.log(data)
					break;
				default :
					break;
			}
			
			//that.$refs.msgPlayer.play()
			
		},

		toTest(){
			this.$router.push({
				path: '/test?agent=laide',
				params : {
					id : 8
				}
			})
		},
		
		
	}
}