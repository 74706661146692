/*
配置文件
*/
//post消息接收端web接口地址，也就是
var webHost = 'https://schat-web.yh.k205.com/web'; //末尾不含 /
//websocket地址
var wsHost = 'wss://schat-web.yh.k205.com/wss'; //需要用nginx反代到服务端口

var isDev = process.env.NODE_ENV == 'development'; //是否为开发环境
if(isDev){
	webHost = 'http://localhost:8787';
	wsHost = 'ws://localhost:7272';
}

export default{
	webHost,
	wsHost,
	isDev
}