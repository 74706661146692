<template>
	<div class="lay-loading">
		<div class="bg" v-if="created">
			<div class="center">
				<van-loading type="spinner" color="#32ae57" :size="size">{{tip2}}</van-loading>
			</div>
		</div>
	</div>
</template>

<script>
import Vue from 'vue';
import { Loading } from 'vant';

Vue.use(Loading);

export default {
	name : 'lay-loading',
	props : {
		size : {
			type : [String,Number],
			dafault : '30'
		},
		tip : ''
	},
	data(){
		return {
			tip2 : '',
			created : false
		}
	},
	created(){
		this.tip2 = this.tip
	},
	mounted(){
		
	},
	methods : {
		show(tip){
			this.tip2 = tip || ''
			this.created = true
		},
		hide(){
			this.created = false
		}
	}
}
</script>
<style scroped>
.lay-loading{}
.lay-loading .bg{ position:fixed; z-index:200; left:0; top:0; width:100%; height:100%; background:transparent;}
.lay-loading .bg .center{ position:fixed; z-index:210; left:50%; top:50%; transform: translate(-50%,-50%); }
</style>