<template>
	<div class="fx jsb message-sending">
		<span class="s1"></span>
		<span class="s2"></span>
		<span class="s3"></span>
	</div>
</template>

<script>
export default {
	name : 'message-sending',
	data(){
		return {
			
		}
	},
	created(){
		
	},
	mounted(){
		
	},
	methods : {
		
	}
}
</script>
<style scroped>
.message-sending{ width:30px; height:10px; padding:0 0px; }
.message-sending span{ width:30%; width:8px; height:8px; position:relative; }
.message-sending span:before{ content:''; position:absolute; width:2px;height:2px; opacity:0; left:50%; top:50%; transform:translate(-50%,-50%); border-radius:50%; background:#ccc; }
.message-sending span.s1:before{
	animation-name: kfs1;
	animation-duration: 1.5s; 
	animation-iteration-count: infinite; /* 无限循环 */
	animation-timing-function: linear; /* 匀速 */
	animation-delay:0s;
}
.message-sending span.s2:before{
	animation-name: kfs1;
	animation-duration: 1.5s; 
	animation-iteration-count: infinite; /* 无限循环 */
	animation-timing-function: linear; /* 匀速 */
	animation-delay:0.3s;
}
.message-sending span.s3:before{
	animation-name: kfs1;
	animation-duration: 1.5s; 
	animation-iteration-count: infinite; /* 无限循环 */
	animation-timing-function: linear; /* 匀速 */
	animation-delay:0.6s;
}
@keyframes kfs1{
	0%{
		transform:translate(-50%,-50%) scale(1);
		opacity:0;
	}
	50%{
		transform:translate(-50%,-50%) scale(3);
		opacity:1;
	}
	100%{
		transform:translate(-50%,-50%) scale(1);
		opacity:0;
	}
}
</style>