import $ from "jquery"
import tool from "@/static/js/tool.js"
import MessageRing from '@/components/message-ring/message-ring.vue'
import MessageDialogBox from '@/components/message-dialog-box/message-dialog-box.vue'
import yourAudio from '@/static/voice/y1981.wav'
import {Notify,Icon,Toast} from 'vant'
import 'vant/lib/index.css'

export default {
	components: {
		MessageRing,
		MessageDialogBox
    },
	data(){
		return {
			msgVoice : yourAudio,
			wsInitMessage : '正在连接服务器...',
			
			chatList : [], //会话列表
			chatDialogList : [], //已载入的会话列表
			currChatId : 0, //当前展示的对话ID
			
			rightTab : 'info',
			//转接对话
			exchange : {
				chatid : 0,
				show : false,
				userList : []
			}
		}
	},
	mounted(){
		//console.log(this.$route.params)
		//console.log(this.$route.query)
		let token = this.$route.query['token'] || ''
		if(this.$cfg.isDev){
			token = 'EHhzDUzIcZxdSJjbsVvQWKEXJDUYaH'
		}
		if(token != ''){
			tool.setAdminToken(token)
		}
		this.$refs.msgPlayer.create()
		
		const that = this;
		this.$wsEventBus.$on('onWsMsgReceived',function(type,data,chatId){
			that.handleWsMsg(type,data,chatId);
		})
		this.$wsInit();
		
		this.loadChatList();
	},
	methods : {
		
		//消息接收
		async handleWsMsg(type,data,chatId){
			switch(type){
				case 'init':
					let res = await tool.postAdmin('init',{
						client_id : data
					})
					if(res.code == 0){
						this.wsInitMessage = '';
					}else if(res.code == 1){
						//Notify({ type: 'primary', message: res.info,duration:10000 });
					}else if(res.code == -1){
						
					}
					break;
				case 'normal' :
				case 'image' :
				case 'file':
					this.$wsEventBus.$emit('onChatMessageReceived',chatId,data);
					if(chatId != this.currChatId){
						//红点、上移
						let index = this.chatList.findIndex(r=>{
							return r.id==chatId
						})
						if(index != -1){
							this.$set(this.chatList[index],'dot',1)
							this.chatList.unshift(...this.chatList.splice(index, 1));
						}
					}
					break;
				case 'newchat':
					let index = this.chatList.findIndex(r=>{
						return r.id== data.id
					})
					if(index != -1){
						//修改昵称头像
						this.$set(this.chatList[index],'face',data.face);
						this.$set(this.chatList[index],'nickname',data.nickname);
					}else{
						this.chatList.push(data);
						index = this.chatList.length - 1;
					}
					this.chatList.unshift(...this.chatList.splice(index, 1));
					break;
				default :
					break;
			}
					
			
		},
		
		//加载会话列表
		async loadChatList(){
			let res = await tool.postAdmin('chatlist',{r:Math.random()},0);
			if(res.code == 0){
				this.chatList = [];
				res.data.list.map(r=>{
					this.chatList.push(r)
				})
				if(this.chatList.length > 0){
					this.choseChat(this.chatList[0].id)
				}
			}
		},
		//选中某个会话
		choseChat(chatid){
			if(this.currChatId == chatid){
				return;
			}
			let index = this.chatDialogList.findIndex(r=>{
				return r.id == chatid
			})
			if(index == -1){
				//创建会话框
				this.chatDialogList.push({
					id : chatid
				})
			}
			this.currChatId = chatid;
			//消除红点、上移动
			index = this.chatList.findIndex(r=>{
				return r.id==chatid
			})
			if(index != -1){
				this.$set(this.chatList[index],'dot',0)
			}
		},
		
		//载入转接对话框
		async chatExchangeShow(e){
			this.exchange.chatid = e;
			this.exchange.userList = [];
			//加载客户列表
			this.exchange.show = true;
			Toast.loading()
			let res = await tool.postAdmin('exchange_userlist',{r:Math.random()},0);
			Toast.clear()
			if(res.code == 0){
				res.data.list.map(r=>{
					this.exchange.userList.push(r)
				})
			}
		},
		//执行转接对话
		async chatExchangeDo(kefuId){
			Toast.loading()
			let res = await tool.postAdmin('exchange_do',{admin_id:kefuId},this.exchange.chatid);
			Toast.clear()
			const that = this;
			if(res.code == 0){
				//转接成功
				this.exchange.show = false
				Toast.success({
					message : res.info,
					onClose :function(){
						//移除对话框
						let index = that.chatDialogList.findIndex(r=>{
							return r.id == that.exchange.chatid
						})
						if(index != -1){
							that.chatDialogList.splice(index,1);
						}else{
							console.log('对话框 失败')
						}
						//移除对话列表
						index = that.chatList.findIndex(r=>{
							return r.id == that.exchange.chatid
						})
						if(index != -1){
							that.chatList.splice(index,1);
						}else{
							console.log('列表 失败')
						}
						//选中新的当前对话
						if(that.chatList.length > 0){
							that.choseChat(that.chatList[0].id)
						}
					}
				})
			}else{
				Toast(res.info)
			}
		},
		
		toTest(){
			this.$router.push({
				path: '/test?agent=laide',
				params : {
					id : 8
				}
			})
		},
		
		
	}
}