<template>
	<div id="app">
		<router-view/>
	</div>
</template>

<script>
export default {
	name: 'App',
	components: {

	}
}
</script>

<style>
html{
	height : 100%;
}
body{
	margin:0;
	padding:0;
	height:100%;
}
#app {
	font-family: Avenir, Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-align: center;
	color: #2c3e50;
	margin-top: 0px;
	font-size:16px;
}
input,select,textarea{ outline:0; }
.fx{ display:flex; }
.f1{ flex:1; }
.fc{ flex-direction:column; }
.xl{ justify-content:flex-start; }
.xc{ justify-content:center; }
.xr{ justify-content:flex-end; }
.yc{ align-items:center; }
.yb{ align-items: flex-end;}
.jsb{ justify-content:space-between; }
.w100{ width:100%; }
.h100{ height:100%; }
</style>
