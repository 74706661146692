import Vue from 'vue';
import $ from "jquery"
import tool from "@/static/js/tool.js"
import MessageSending from '@/components/message-sending/message-sending.vue'
import MessageEditor from '@/components/message-editor/message-editor.vue'
import yourAudio from '@/static/voice/y1981.wav'
import {Notify,Icon,Toast,Loading,Popup} from 'vant'
import 'vant/lib/index.css'

Vue.use(Popup);

export default {
	name : 'message-dialog-box',
	props : {
		//会话ID
		ChatId : {
			type : [Number,String],
			required : true
		},
		//当前用户级别
		Level : {
			type : String,
			required : true
		}
	},
	components: {
		MessageSending,
		MessageEditor
    },
	data(){
		return {
			dialogId : '', //聊天内容展示框ID
			
			//对方用户信息
			user : {
				face : 'https://oss.ttyhkj.com/static/image/face0.jpg',
				nickname : '',
			},

			
			list : [], //消息列表
			localLastMsgId : 0, //本地消息ID，发送消息前先展示到列表
			historyLoading : false, //时候正在加载历史信息
			
		}
	},
	created(){
		//console.log('聊天窗口创建完毕')
		this.dialogId = 'msgDialog'+Math.floor(Math.random() * 90000000 + 10000000).toString();
		
	},

	async mounted(){
		const that = this;
		//console.log('聊天窗口挂载完毕')
	
		//监听加入新消息事件
		this.$wsEventBus.$on('onChatMessageReceived',function(chatid,data){
			//console.log('收到消息',chatid,data)
			if(chatid != that.ChatId){
				return
			}
			that.appendMessage(chatid,data)
		})
		
		if(this.ChatId > 0){
			await this.loadHistoryMessage(false)
			await this.loadUserInfo()
		}
		
	},
	methods : {
		//加载历史消息记录
		async loadHistoryMessage(showTip){
			if(this.historyLoading){
				return;
			}
			if(typeof showTip == 'undefined'){
				showTip = true
			}
			let lastMsgId = 0;
			this.list.map(r=>{
				if(lastMsgId == 0){
					lastMsgId = r.id;
				}else if(r.id > 0){
					lastMsgId = Math.min(lastMsgId,r.id)
				}
			})
			this.historyLoading = true;
			let res;
			if(this.Level == 'admin'){
				res = await tool.postAdmin('history',{lastId:lastMsgId},this.ChatId)
			}else{
				res = await tool.postAgent('history',{lastId:lastMsgId})
			}
			this.historyLoading = false;
			if(res.code == 0){
				res.data.list.map(r=>{
					//当前没消息展示时滚动到底部，否则滚动到顶部
					this.appendMessage(res.data.chatId,r,showTip ? 'up' : 'down')
				})
			}else{
				if(showTip){
					Toast(res.info)
				}
			}
		},
		
		//加载对方信息
		async loadUserInfo(){
			let res;
			if(this.Level == 'admin'){
				res = await tool.postAdmin('agent_info',{r:Math.random()},this.ChatId)
			}else{
				res = await tool.postAgent('chatdialog_info',{r:Math.random()})
			}
			if(res.code == 0){
				this.user.face = res.data.face;
				this.user.nickname = res.data.nickname
			}
		},
		
		//消息加到展示区
		appendMessage(chatid,message,scrollType){
			//重复消息过滤
			let index = this.list.findIndex(r=>{
				return r.id == message.id
			})
			if(index != -1){
				return;
			}
			scrollType = scrollType || 'down'; //滚动类型
			//按ID升序
			//id是负数，不参与排序
			let fushu = this.list.filter(r=>{
				return r.id < 1
			})
			let zhengshu = this.list.filter(r=>{
				return r.id > 0
			})
			let xin = [];
			if(message.id < 1){
				fushu.push(message)
			}else{
				xin.push(message)
			}
			
			let rrr = [...zhengshu, ...xin].sort((a, b) => a.id - b.id);
			rrr.push(...fushu)
			this.list = rrr
			
			//this.list.push(message);
			this.$nextTick(()=>{
				//滚动到底部
				let type = message.type || '';
				let ms = type == 'image' ? 200 : 10;
				setTimeout(()=>{
					let el = '#' + this.dialogId;
					if($(el).length > 0){
						if(scrollType == 'up'){
							$(el).scrollTop(0);
						}else{
							$(el).scrollTop($(el)[0].scrollHeight);
						}
					}
				},ms)
			})
		},

		//更改多个消息的发送结果
		updateMessageSendResult(msgIdArr,result){
			for(let i=0;i<msgIdArr.length;i++){
				let index = this.list.findIndex(r=>{
					return r.id == msgIdArr[i]
				})
				if(index == -1){
					continue;
				}
				if(typeof result['msgId'] != 'undefined'){
					this.$set(this.list[index],'id',result['msgId'])
				}
				if(typeof result['status'] != 'undefined'){
					this.$set(this.list[index],'send_status',result['status'])
				}
				if(typeof result['time'] != 'undefined'){
					this.$set(this.list[index],'time',result['time'])
				}
			}
		},
		
		//收到发送normal消息的事件
		beforeNormalMessage(message){
			this.sendMessage('normal',{text:message})
		},
		//收到图片上传成功消息
		beforeImageMessage(url){
			this.sendMessage('image',{url:url})
		},
		//收到附件上传成功消息
		beforeFileMessage(filename,url){
			this.sendMessage('file',{
				name : filename,
				url : url
			})
		},
		
		//发送消息
		async sendMessage(type,data){
			//拼接本地消息
			this.localLastMsgId--
			let item = Object.assign({},{
				id : this.localLastMsgId,
				type : type,
				content : data,
				isLeft : 0,
				time : '',
				send_status : 0
			})
			this.appendMessage(this.ChatId,item)
			//发送消息
			let res;
			if(this.Level == 'admin'){
				res = await tool.postAdmin(type,data,this.ChatId)
			}else{
				res = await tool.postAgent(type,data)
			}
			//console.log(res)
			//更新发送状态
			this.updateMessageSendResult([item.id],{
				msgId : res.code == 0 ? res.data.msgId : item.id,
				time : res.code == 0 ? res.data.time : '',
				status : res.code == 0 ? 1 : 2,
			})
		},
		
		//触发转接对话
		toExchange(){
			this.$emit('onExchange',this.ChatId)
		},
		
		//点击了聊天记录中的图片
		msgImageClick(item){
			let wWidth = $(window).width();
			let wHeight = $(window).height();
			let w = Math.floor(wWidth * 0.6);
			let h = Math.floor(wHeight * 0.6);
			let left = Math.floor((wWidth - w) / 2);
			let top = Math.floor((wHeight - h) / 2);
			window.open(item.content.url,'mywindow','width='+w+',height='+h+',location=no,left='+left+',top='+top)
		},
		//点击了聊天记录中的附件下载
		msgFileClick(item){
			let wWidth = $(window).width();
			let wHeight = $(window).height();
			let w = Math.floor(wWidth * 0.6);
			let h = Math.floor(wHeight * 0.6);
			let left = Math.floor((wWidth - w) / 2);
			let top = Math.floor((wHeight - h) / 2);
			window.open(item.content.url,'mywindow','width='+w+',height='+h+',location=no,left='+left+',top='+top)
		},

		toTest(){
			this.$router.push({
				path: '/test?agent=laide',
				params : {
					id : 8
				}
			})
		},
		
		
	}
}