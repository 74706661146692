<template>
<div class="fx h100 app">
	
	<div class="fx fc h100 page-left">
		<div class="fx yc left-tool">
			<div class="f1">客服沟通</div>
			<div class="btn" title="新建对话" style="display: none;">
				<van-icon name="add-o" size="24" />
			</div>
		</div>
		<div class="f1 w100 user-list">
			<div :class="['fx xl item',{'active':item.id==currChatId}]" v-for="item in chatList" :key="item.id" @click="choseChat(item.id)">
				<div class="face">
					<img :src="item.face" alt="" />
				</div>
				<div class="f1 fx fc xc info">
					<div class="name">{{item.nickname}}</div>
					<div class="msg" v-if="item.lastMsg">{{item.lastMsg.content}}</div>
				</div>
				<div class="dot" v-if="item.dot==1"></div>
			</div>
			
		</div>
	</div>
	
	<div class="f1 fx fc h100  page-center">
		
		<message-dialog-box v-for="item in chatDialogList" :key="item.id"
		:ChatId="item.id" :ref="'chatdialog'+item.id" :abc="item.id"
		@onExchange="chatExchangeShow"
		:style="{'z-index':item.id==currChatId?500:200}"
		Level="admin"></message-dialog-box>
		
		<div v-if="chatDialogList.length < 1" style="color:#cfcfcf; font-size:30px; line-height: 100px;">没有会话记录</div>
		
	</div>
	<!--page center end-->
	<message-ring ref='msgPlayer' :src='msgVoice'></message-ring>
	
	<!-- 转接对话 -->
	<van-popup v-model="exchange.show" closeable 
	:close-on-click-overlay="false"
	:style="{ height: '50%','width':'50%','z-index':'5000' }">
		<div class="fx fc w100 h100 spop">
			<div class="fx yc stitle">转接对话</div>
			<div class="f1 scontent">
				<div class="exchange-list">
					<div :class="['fx yc item',{'offline':item.online==0}]" v-for="item in exchange.userList" :key="item.id">
						<div class="face"><img :src="item.headimg" alt=""></div>
						<div class="f1 nickname">{{item.nickname}}{{item.online==1 ? '' : '[离线]'}}</div>
						<van-icon name="share-o" size="24" @click="chatExchangeDo(item.id)" />
					</div>
				</div>
			</div>
		</div>
	</van-popup>
	
	
	<div class="fx xc yc ws-init" v-if="wsInitMessage!=''">{{wsInitMessage}}</div>
	
</div>
</template>

<script>
import chatjs from "./chatjs.js"
export default {
	...chatjs
}
</script>
<style scroped>
@import url("chatcss.css");
</style>