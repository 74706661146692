import $ from 'jquery'
import cfg from "@/static/js/config.js"

const HOST = cfg.webHost;

function cacheGet(key){
	if(!window.localStorage){
		return ''
	}
	return window.localStorage.getItem(key) || ''
}

function cacheSet(key,value){
	window.localStorage && window.localStorage.setItem(key,value)
}

function setAgentToken(token){
	cacheSet('ag_token',token)
}

function setAdminToken(token){
	cacheSet('ad_token',token)
}

function getAgentToken(){
	return cacheGet('ag_token')
}

function getAdminToken(){
	return cacheGet('ad_token')
}

function post(url,param){
	return new Promise((resolve)=>{
		$.ajax({
			url : url,
			type : 'POST',
			data : param,
			dataType : 'JSON',
			success : function(res){
				return resolve(res)
			},
			error : function(){
				return resolve({
					code : -1,
					info : '网络错误'
				})
			}
		})
	})
}

//代理发送消息
//参数：消息类型、消息体
async function postAgent(type,data){
	let param = {
		type : type,
		token : getAgentToken(),
		data : JSON.stringify(data)
	}
	return await post(HOST + '/agent',param)
}

//客服发送消息
//参数：消息类型、消息体
async function postAdmin(type,data,chatId){
	let param = {
		type : type,
		token : getAdminToken(),
		data : JSON.stringify(data),
		chatId
	}
	return await post(HOST + '/admin',param)
}

function parseQuery(query){
	let arr = query.split('&')
	let result = {}
	for(let i=0;i<arr.length;i++){
		let kv = arr[i].split('=')
		if(kv.length != 2 || kv[0] == ''){
			continue;
		}
		result[kv[0]] = kv[1]
	}
	return result
}

//选择本地文件
function selectLocalFile(accept){
	accept = accept || 'image/*'
	return new Promise((resolve)=>{
		$('.select-local-image').remove();
		var r = Math.floor(Math.random() * 900000) + 100000;
		$('body').append("<div class='select-local-image' style='width:0;height:0;overflow:hidden'><input type='file' accept='"+ accept +"' id='" + r + "' value='' /></div>");
		$('#' + r).change(function(){
			var that = this;
			if(this.files.length < 1){
				return resolve(null);
			}
			return resolve(this.files[0]);
		})
		$('#' + r).trigger('click');
	})
}

//上传本地文件
function uploadLocalFile(file,userLevel){
	return new Promise((resolve)=>{
		if(file.size > 5 * 1024 * 1024){
			return resolve({code : -1,info : '文件大小限制5M'})
		}
		var fd = new FormData();
		fd.append('file', file);
		fd.append('level', userLevel);
		fd.append('token', userLevel == 'admin' ? getAdminToken() : getAgentToken());
		$.ajax({
			type: 'POST',
			url: HOST + '/index/index/upload',
			data: fd,
			dataType:'JSON',
			contentType: false,
			processData: false,
			success: function(res) {
				return resolve(res)
			},error:function(){
				return resolve({code : -1,info : '网络错误'})
			}
		})
	});
}

export default {
	setAgentToken,
	setAdminToken,
	postAgent,
	postAdmin,
	parseQuery,
	selectLocalFile,
	uploadLocalFile
}