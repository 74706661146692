import Vue from 'vue'
import App from './App.vue'
import router from './router'

Vue.config.productionTip = false

import cfg from "@/static/js/config.js"
Vue.prototype.$cfg = cfg

import WS from "@/static/js/ws.js"
Vue.prototype.$wsInit = WS.ws_connect
Vue.prototype.$wsEventBus = WS.ws_eventbus

new Vue({
	router,
	render: h => h(App),
}).$mount('#app')
